import locale18a23ec0 from '../../locales/ru-RU.js'
import locale5f2cf880 from '../../locales/en-EN.js'
import localee1632080 from '../../locales/cn-CN.js'
import locale49ccb8a0 from '../../locales/tr-TR.js'
import locale67ae66a0 from '../../locales/es-ES.js'
import localee30d5100 from '../../locales/hi-HI.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"ru","name":"Ру","file":"ru-RU.js","iso":"ru-RU","domain":"1grizzlysms.com"},{"code":"en","name":"En","file":"en-EN.js","iso":"en-US","domain":"grizzlysms.com"},{"code":"cn","name":"Cn","file":"cn-CN.js","iso":"zh_CN","domain":"grizzlysms.com"},{"code":"tr","name":"Tr","file":"tr-TR.js","iso":"tr_TR","domain":"grizzlysms.com"},{"code":"es","name":"Es","file":"es-ES.js","iso":"es_ES","domain":"grizzlysms.com"},{"code":"hi","name":"Hi","file":"hi-HI.js","iso":"hi_HI","domain":"grizzlysms.com"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "/var/www/grizzlysms.com/release-2025.02.14-07:14:09/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  useRedirectCookie: false,
  baseURL: "https://grizzlysms.com",
  useCookie: false,
  normalizedLocales: [{"code":"ru","name":"Ру","file":"ru-RU.js","iso":"ru-RU","domain":"1grizzlysms.com"},{"code":"en","name":"En","file":"en-EN.js","iso":"en-US","domain":"grizzlysms.com"},{"code":"cn","name":"Cn","file":"cn-CN.js","iso":"zh_CN","domain":"grizzlysms.com"},{"code":"tr","name":"Tr","file":"tr-TR.js","iso":"tr_TR","domain":"grizzlysms.com"},{"code":"es","name":"Es","file":"es-ES.js","iso":"es_ES","domain":"grizzlysms.com"},{"code":"hi","name":"Hi","file":"hi-HI.js","iso":"hi_HI","domain":"grizzlysms.com"}],
  localeCodes: ["ru","en","cn","tr","es","hi"],
  additionalMessages: [],
}

export const localeMessages = {
  'ru-RU.js': () => Promise.resolve(locale18a23ec0),
  'en-EN.js': () => Promise.resolve(locale5f2cf880),
  'cn-CN.js': () => Promise.resolve(localee1632080),
  'tr-TR.js': () => Promise.resolve(locale49ccb8a0),
  'es-ES.js': () => Promise.resolve(locale67ae66a0),
  'hi-HI.js': () => Promise.resolve(localee30d5100),
}
